import React, { ReactNode, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { AuthRole } from '@coachhubio/auth-role';
import { useRouter } from 'next/router';

import { destroyAccessToken, getAccessToken } from '../utils/accessToken';
import { performLogin, performLogout } from '../utils/authentication';
import { Roles } from '../utils/Roles';

export interface User {
  id: string;
  organizationId: string;
  accountId: string;
  tenantId: string;
  roles: Roles;
}

export interface UserInput extends Omit<User, 'roles'> {
  roles: string[];
}

export type AuthContextData = {
  login: (user: string, password: string) => Promise<void>;
  logout: () => void;
  user: User;
  setUser(u: User): void;
};

const AuthContext = React.createContext<AuthContextData | undefined>(undefined);

function AuthProvider(props: { children: ReactNode; user: UserInput }) {
  const { logout: auth0logout } = useAuth0();
  const { reload } = useRouter();

  const login = (user: string, password: string) => performLogin(user, password).then(() => reload());
  const logout = () => {
    performLogout()
      .then(() =>
        auth0logout({
          returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
        })
      )
      .then(() => {
        destroyAccessToken();
        // Remove default language setting from NominateCoacheeForm
        localStorage.removeItem('defaultAccountLanguage');
      });
  };

  const providedUser: User = { ...props.user, roles: new Roles(props.user.roles) };
  const [user, setSelectedUser] = useState(providedUser);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        setUser: (u: User) => {
          setSelectedUser(u);
        },
      }}
      {...props}
    />
  );
}

const useUser = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  if (context?.user) {
    return context?.user;
  }
  throw new Error('no user found!');
};

const useAccessToken = () => {
  return `${getAccessToken()}`;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth, AuthContext, useUser, AuthRole, useAccessToken };
