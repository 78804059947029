import React from 'react';

import { createStyles, makeStyles } from '@coachhubio/ui-components';
import Router from 'next/router';

import { LoadingState } from './components/LoadingState';

const useStyles = makeStyles(() =>
  createStyles({
    pageLoader: {
      height: '100vh',
    },
  })
);

const LoadingApp = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles({});
  React.useEffect(() => {
    const startLoading = () => {
      setLoading(true);
    };
    const endLoading = () => {
      setLoading(false);
    };

    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', endLoading);
    Router.events.on('routeChangeError', endLoading);

    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', endLoading);
      Router.events.off('routeChangeError', endLoading);
    };
  }, []);

  return <>{loading ? <LoadingState className={`${classes.pageLoader}`} /> : children}</>;
};

export default LoadingApp;
