import { useEffect } from 'react';

import { init } from '@socialgouv/matomo-next';

export const useInitializeMatomo = () => {
  useEffect(() => {
    const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
    const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;

    const initMatomo = async () => {
      if (MATOMO_URL && MATOMO_SITE_ID) {
        init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
      }
    };

    initMatomo();
  }, []);
};