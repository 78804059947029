import jwt_decode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';

export const isTokenStillValid = (encodedToken: string) => {
  const decoded: JwtPayload = jwt_decode<JwtPayload>(encodedToken);
  if (decoded.exp) {
    const currentTime = Math.floor(Date.now() / 1000);
    const expiryThresholdInSeconds = parseInt(process.env['security.expiryThresholdInSeconds'] || '90', 10);
    // refresh a token
    return decoded.exp - currentTime > expiryThresholdInSeconds;
  }
  return false;
};

export const safeInput = (input: string) => decodeURIComponent(`${encodeURIComponent(input)}`);

export const addMonthsToDate = (numberOfMonths: number, date: number) => {
  const currentDate = new Date(date);
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + numberOfMonths, currentDate.getDate());
};

/**
 * Host used to authorize users for the help center
 */
export const getBffHost = (tenantId: string) => {
  const bffHost =
    tenantId !== 'us-1' ? process.env.NEXT_PUBLIC_COACHING_WEB_BFF : process.env.NEXT_PUBLIC_COACHING_WEB_BFF_US_1;
  const zdSupportDomain = process.env.NEXT_PUBLIC_ZENDESK_SUPPORT_DOMAIN as string;
  return `${bffHost}/zendesk-auth/sso?return_to=${encodeURIComponent(zdSupportDomain)}`;
};

export const toDateObject = (value: string | number | Date): Date => {
  return value instanceof Date ? value : isNaN(+value) ? new Date(value) : new Date(+value);
};

export const convertDateTimeToDate = (datetime: string | number | Date, fallback = '-') => {
  const date = toDateObject(datetime);
  if (!date || isNaN(+date)) return fallback;
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
  }).format(date);
};

export const capitalize = (str: string, all = false): string => {
  const cap = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return !all ? cap(str) : str.split(' ').map(cap).join(' ');
};

export function monthsDiff(dateFrom: Date, dateTo: Date): number | null {
  if (!dateFrom || !dateTo) return null;
  const fromDateWithoutTime = moment(dateFrom).startOf('day');
  const toDateWithoutTime = moment(dateTo).startOf('day');
  return Math.abs(fromDateWithoutTime.diff(toDateWithoutTime, 'months'));
}
