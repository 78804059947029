import React from 'react';

import { Toaster } from '@coachhubio/nova-toast';
import { useNotification } from 'context/NotificationContext';

export function Toast() {
  const { notification } = useNotification();

  return <Toaster zIndex={15} ref={notification}></Toaster>;
}
