import React, { useRef } from 'react';

import { ToasterRef, ToastProps } from '@coachhubio/nova-toast';

const NotificationContext = React.createContext<
  | {
      notification: React.RefObject<ToasterRef> | undefined;
      setNotification: (e: ToastProps) => void;
    }
  | undefined
>(undefined);

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const notification = useRef<ToasterRef>(null);

  const setNotification = (e: ToastProps) => {
    notification.current?.add(e);
  };

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>{children}</NotificationContext.Provider>
  );
}

function useNotification() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}

export { NotificationProvider, useNotification };
