export interface Redirection {
  source: string;
  destination: string;
  permanent: boolean;
}

export interface RedirectProps {
  enableCoacheeList: boolean;
  showInsightsPlus: boolean;
}
export const redirection = ({ enableCoacheeList, showInsightsPlus }: RedirectProps) => {
  const insightsToDashboard = new Map<string, Redirection>([
    [
      '/dashboard',
      {
        source: '/dashboard',
        destination: '/',
        permanent: false,
      },
    ],
    [
      '/insights',
      {
        source: '/insights',
        destination: '/',
        permanent: false,
      },
    ],
  ]);

  const coacheesToPrograms = new Map<string, Redirection>([
    [
      '/coachees',
      {
        source: '/coachees',
        destination: '/programs',
        permanent: false,
      },
    ],
  ]);

  const nominateToProgram = new Map<string, Redirection>([
    [
      '/nominate-coachee',
      {
        source: '/nominate-coachee',
        destination: '/programs',
        permanent: false,
      },
    ],
  ]);

  const programsCoacheeToPrograms = new Map<string, Redirection>([
    [
      '/programs/coachee',
      {
        source: '/programs/coachee',
        destination: '/programs',
        permanent: false,
      },
    ],
  ]);

  const insightsPlusToDashboard = new Map<string, Redirection>([
    [
      '/insightsplus',
      {
        source: '/insightsplus',
        destination: '/',
        permanent: false,
      },
    ],
  ]);

  const coacheeListToDashboard = new Map<string, Redirection>([
    [
      '/coachee-list',
      {
        source: '/coachee-list',
        destination: '/',
        permanent: false,
      },
    ],
  ]);

  const programsCoacheeRedirection = !enableCoacheeList ? programsCoacheeToPrograms : [];
  const insightsPlusRedirection = !showInsightsPlus ? insightsPlusToDashboard : [];
  const coacheeListRedirection = !enableCoacheeList ? coacheeListToDashboard : [];

  return new Map<string, Redirection>([
    [
      '/admin/subsidiary/edit',
      {
        source: '/admin/subsidiary/edit',
        destination: '/admin/business-units/edit ',
        permanent: true,
      },
    ],

    ...insightsToDashboard,
    ...coacheesToPrograms,
    ...programsCoacheeRedirection,
    ...insightsPlusRedirection,
    ...coacheeListRedirection,
  ]);
};
