import { gql } from '@apollo/client';

export const GET_ORGANIZATION_QUERY = gql`
  query GetOrganization($organizationId: ID, $accountId: ID) {
    getOrganization(id: $organizationId, accountId: $accountId) {
      ... on Organization {
        id
        name
        logo
        programManager {
          id
        }
      }
    }
  }
`;

export const LIST_HR_MEMBERS = gql`
  query listHR($input: ListAllMemberInput, $paginationInput: PaginationInput) {
    listAllMembers(input: $input, paginationInput: $paginationInput) {
      ... on MemberConnection {
        edges {
          node {
            id
            userId
            email
            firstName
            lastName
            signedUpAt
            picture
            position
            organization {
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $logo: String, $programManagerId: ID) {
    createOrganization(input: { name: $name, logo: $logo, programManagerId: $programManagerId }) {
      __typename
      ... on Organization {
        id
        accountId
        name
        logo
      }
    }
  }
`;
