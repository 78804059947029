import { gql } from '@apollo/client';

const MAX_LEVEL_BUSINESS_UNIT = Number(process.env.NEXT_PUBLIC_MAX_LEVEL_BUSINESS_UNIT || 10);

// To Generate dynamic levels
const child = `children {
      ...ChildrenFields 
      `;
const closer = '}';

export const CHILDREN_FIELDS = gql`
  fragment ChildrenFields on AccountTreeResult {
    id
    name
    parentId
    tenantId
  }
`;

export const CHILDREN_RECURSIVE = gql`
  fragment ChildrenRecursive on AccountTreeResult {
      id
      name
      parentId
      tenantId
      children {
          ...ChildrenFields
          ${child.repeat(MAX_LEVEL_BUSINESS_UNIT)}
          ${closer.repeat(MAX_LEVEL_BUSINESS_UNIT)}
      }
  }
  ${CHILDREN_FIELDS}
`;
