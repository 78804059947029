import React, { useMemo } from 'react';

import { DropdownButton } from '@coachhubio/nova-dropdown-button';
import { GlobeIcon } from '@coachhubio/nova-icon';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LOCALES } from 'translations/i18n';

const languageMap: { [key: string]: string } = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const languages = useMemo(() => {
    return Object.entries(SUPPORTED_LOCALES).map(([key, value]) => ({
      key,
      value,
      translation: languageMap[key],
    }));
  }, []);

  const handleChangeLanguage = (value: any) => {
    localStorage.setItem('appLanguage', value);
    i18n.changeLanguage(value);
  };

  return (
    <DropdownButton
      menuItems={[
        {
          items: languages.map(({ value, translation }) => ({
            label: translation,
            onClick: () => handleChangeLanguage(value),
          })),
        },
      ]}
      buttonVariant="icon"
      buttonSize="s"
      chevronPosition="end"
      autoFlip
    >
      <GlobeIcon />
    </DropdownButton>
  );
};

export default LanguageSelector;
