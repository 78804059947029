import React from 'react';

import { CircularProgress, createStyles, makeStyles } from '@coachhubio/ui-components';

import { FrameWrapper } from './FrameWrapper';

interface LoadingStateProps {
  className?: string;
  withBorder?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      padding: '0 24px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export function LoadingState({ withBorder = false, className, ...props }: LoadingStateProps) {
  const classes = useStyles({});
  return (
    <FrameWrapper withBorder={withBorder} className={`${classes.root} ${className}`} {...props}>
      <CircularProgress disableShrink />
    </FrameWrapper>
  );
}
