import { createContext, useContext, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { LIST_ACCOUNTS_ROOT_ID_QUERY } from 'apollo/account-api/account';
import { AccountCoacheeNominationAccess } from 'apollo/account-api/types/global';
import { ListAccounts, ListAccountsVariables } from 'apollo/account-api/types/ListAccounts';
import { CLIENTS } from 'apollo/clients';
import { AuthRole, useUser } from 'context/AuthContext';
import { useCoacheesCountByProgram } from 'hooks/useCoacheesCountByProgram';
import useFetchPrograms from 'hooks/useFetchPrograms';
import { useFlags } from 'hooks/useFlags';
import { sortBy } from 'lodash';

import { Program } from '../types';

export type CoacheeListProgramsContextData = {
  programs: Program[];
  loading: boolean;
  canNotNominateCoachee: boolean;
  handleSearchChange: (term: string) => void;
  searchTerm: string;
  coacheesCountByProgram: Record<string, number>;
};

const ProgramsContext = createContext<CoacheeListProgramsContextData | undefined>(undefined);

type ProgramsProviderProps = {
  children: React.ReactElement;
};

function ProgramsProvider({ children }: ProgramsProviderProps) {
  const { accountId, roles } = useUser();
  const { loading, programs = [] } = useFetchPrograms();
  const { loading: loadingCoacheesCount, coacheesCountByProgram } = useCoacheesCountByProgram(programs);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { enableCoachhubFrameworkProgramPage } = useFlags();

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const filteredPrograms = programs.filter((program) =>
    program.name?.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  const memoPrograms = useMemo(() => {
    const currentPrograms =
      filteredPrograms.length > 0 || searchTerm.trim().length > 0 ? [...filteredPrograms] : [...(programs || [])];
    if (enableCoachhubFrameworkProgramPage) {
      return sortBy(currentPrograms, (program) => -new Date(program.updatedAt).getTime());
    }
    return currentPrograms;
  }, [enableCoachhubFrameworkProgramPage, programs, filteredPrograms, searchTerm]);

  const { data: account } = useQuery<ListAccounts, ListAccountsVariables>(LIST_ACCOUNTS_ROOT_ID_QUERY, {
    context: { clientName: CLIENTS.account },
    skip: !accountId,
    variables: {
      input: {
        accountIds: [accountId],
        accountId,
      },
    },
  });

  const rootId = account?.listAccounts?.edges[0].node.rootId;
  const coacheeNominationAccess = account?.listAccounts?.edges[0].node.coacheeNominationAccess;
  const canNotNominateCoachee = roles.has(AuthRole.CUSTOMER)
    ? coacheeNominationAccess === AccountCoacheeNominationAccess.ROOT_ONLY && accountId !== rootId
    : false;

  return (
    <ProgramsContext.Provider
      value={{
        canNotNominateCoachee,
        loading: loading || loadingCoacheesCount,
        programs: memoPrograms,
        coacheesCountByProgram,
        handleSearchChange,
        searchTerm,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
}

function useProgramsContext() {
  const context = useContext(ProgramsContext);
  if (context === undefined) {
    throw new Error('useProgramsContext must be used within a ProgramsContext');
  }
  return context;
}

export { ProgramsProvider, useProgramsContext };
