import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { customHeaders } from 'customHeaders';

import { getAccessToken } from '../utils/accessToken';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    // TODO: double check how 500 errors are handler and if they are pushed in network errors and not here?
    // we don't want to push 4xx error to sentry
    // graphQLErrors.map(({ message, locations, path }) => Sentry.captureMessage(message));
  }
  if (networkError) {
    const traceId = operation.getContext().response?.headers.get('x-ch-trace-id') ?? undefined;
    Sentry.setTag('trace-id', traceId);
    Sentry.captureException(networkError);
  }
});

export enum CLIENTS {
  account = 'account',
  organization = 'organization',
  'organization-eu-1' = 'organization',
  'organization-us-1' = 'organization-us-1',
}

export const getOrganizationClientName = (tenantId: string) => {
  return CLIENTS[`organization-${tenantId}` as keyof typeof CLIENTS];
};

export const organizationApiClient = () => {
  const organizationHost = process.env['apiHosts.organization'] ?? process.env.NEXT_PUBLIC_ORG_API;
  const organizationUS1Host = process.env['apiHosts.organizationUS1'] ?? process.env.NEXT_PUBLIC_ORG_API_US_1;
  const accountHost = process.env['apiHosts.account'] ?? process.env.NEXT_PUBLIC_ACCOUNT_API;

  const organizationHttpLink = createHttpLink({
    uri: `${organizationHost}/graphql`,
  });

  const organizationUS1HttpLink = createHttpLink({
    uri: `${organizationUS1Host}/graphql`,
  });

  const accountHttpLink = createHttpLink({
    uri: `${accountHost}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: getAccessToken(),
        ...customHeaders,
      },
    };
  });

  const links = ApolloLink.split(
    (operation) => operation.getContext().clientName === CLIENTS.account,
    ApolloLink.from([authLink, errorLink, accountHttpLink]),
    ApolloLink.split(
      (operation) => operation.getContext().clientName === CLIENTS['organization-us-1'],
      ApolloLink.from([authLink, errorLink, organizationUS1HttpLink]),
      ApolloLink.from([authLink, errorLink, organizationHttpLink])
    )
  );

  return new ApolloClient({
    link: links,
    cache: new InMemoryCache(),
  });
};
