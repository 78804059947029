import { AuthRole } from '@coachhubio/auth-role';
import { useFlags as getLDFlags } from 'launchdarkly-react-client-sdk';

import { useUser } from '../context/AuthContext';

// reduce LD MAU on development by reducing the number of calls and mocking development response where all flags are set to true
interface LDFlags {
  'enable-add-new-company': boolean;
  'enable-unlink-business-unit': boolean;
  'coachee-nomination-access': boolean;
  'enable-contract-admin': boolean;
  'show-dashboard-sections': boolean;
  'enable-coachee-list': boolean;
  'filter-inactive': boolean;
  'show-insights-plus': boolean;
  'show-multi-rater-feedback-page': boolean;
  'show-codevhubs-page': boolean;
  'enable-edit-organization-with-nova': boolean;
  'enable-flash-coaching-price-classification': boolean;
  'enable-nominate-coachee-page-with-nova': boolean;
  'allow-program-creation-for-coachhub-framework': boolean;
  'enable-budget-page': boolean;
  'use-coachhub-framework-drawer': boolean;
  'enable-coachhub-framework-program-page': boolean;
  'enable-preview-question-assessment-modal': boolean;
  'enable-edit-for-coachhub-framework-program': boolean;
  'enable-self-assessment-in-program-creation': boolean;
  'enable-program-creation-with-coaching-use-cases': boolean;
  'disable-program-duration-limit': boolean;
  'enable-coachee-nomination-v2': boolean;
}

function Flags() {
  const { roles } = useUser();

  let flags = {
    'enable-add-new-company': true,
    'enable-unlink-business-unit': true,
    'coachee-nomination-access': true,
    'enable-contract-admin': true,
    'show-dashboard-sections': true,
    'enable-coachee-list': true,
    'filter-inactive': false,
    'show-insights-plus': false,
    'show-multi-rater-feedback-page': false,
    'show-codevhubs-page': false,
    'enable-edit-organization-with-nova': true,
    'enable-flash-coaching-price-classification': false,
    'allow-program-creation-for-coachhub-framework': true,
    'enable-budget-page': true,
    'use-coachhub-framework-drawer': true,
    'enable-coachhub-framework-program-page': true,
    'enable-preview-question-assessment-modal': true,
    'enable-edit-for-coachhub-framework-program': true,
    'enable-self-assessment-in-program-creation': true,
    'enable-program-creation-with-coaching-use-cases': true,
    'disable-program-duration-limit': false,
    'enable-coachee-nomination-v2': true,
  };

  if (process.env.NEXT_PUBLIC_LAUNCHDARKLY_ENV !== 'dev') {
    flags = getLDFlags() as LDFlags;
  }

  const {
    'enable-add-new-company': enableAddNewCompany,
    'enable-unlink-business-unit': enableUnlinkBusinessUnit,
    'coachee-nomination-access': coacheeNominationAccess,
    'enable-contract-admin': enableContractAdmin,
    'show-dashboard-sections': showDashboardSections,
    'enable-coachee-list': enableCoacheeList,
    'filter-inactive': filterInactive,
    'show-insights-plus': showInsightsPlus,
    'show-multi-rater-feedback-page': showMultiRaterFeedbackPage,
    'show-codevhubs-page': showCoDevHubsPage,
    'enable-edit-organization-with-nova': enableEditOrganizationWithNova,
    'enable-flash-coaching-price-classification': enableFlashCoachingPriceClassification,
    'allow-program-creation-for-coachhub-framework': allowProgramCreationForCoachHubFramework,
    'enable-budget-page': enableBudgetPage,
    'use-coachhub-framework-drawer': useCoachhubFrameworkDrawer,
    'enable-coachhub-framework-program-page': enableCoachhubFrameworkProgramPage,
    'enable-preview-question-assessment-modal': enablePreviewQuestionAssessmentModal,
    'enable-edit-for-coachhub-framework-program': enableEditForCoachHubFrameworkProgram,
    'enable-self-assessment-in-program-creation': enableSelfAssessmentInProgramCreation,
    'enable-program-creation-with-coaching-use-cases': enableProgramCreationWithCoachingUseCases,
    'disable-program-duration-limit': disableProgramDurationLimit,
    'enable-coachee-nomination-v2': enableCoacheeNominationV2,
  } = flags;

  return {
    enableAddNewCompany,
    enableCoacheeList,
    enableUnlinkBusinessUnit,
    coacheeNominationAccess,
    enableContractAdmin: enableContractAdmin && roles.has(AuthRole.OPERATIONS),
    showDashboardSections,
    filterInactive,
    showInsightsPlus,
    showMultiRaterFeedbackPage,
    showCoDevHubsPage,
    enableEditOrganizationWithNova,
    enableFlashCoachingPriceClassification,
    allowProgramCreationForCoachHubFramework,
    enableBudgetPage,
    useCoachhubFrameworkDrawer,
    enableCoachhubFrameworkProgramPage,
    enablePreviewQuestionAssessmentModal,
    enableEditForCoachHubFrameworkProgram,
    enableSelfAssessmentInProgramCreation,
    enableProgramCreationWithCoachingUseCases,
    disableProgramDurationLimit,
    enableCoacheeNominationV2,
  } as const;
}

export function useFlags() {
  return Flags();
}
