import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Combobox } from '@coachhubio/nova-combobox';
import { Option } from '@coachhubio/nova-select';
import { Typography } from '@coachhubio/ui-components';
import { GET_ACCOUNT } from 'apollo/account-api/account';
import { LinkAccountInput, ListAccountInput } from 'apollo/account-api/types/global';
import { CLIENTS } from 'apollo/clients';
import { GET_ORGANIZATION_QUERY } from 'apollo/organization-api/organization';
import useFetchAccounts, { FetchAccountsInput } from 'hooks/useFetchAccounts';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

type OrganizationSelectorProps = {
  setIsLoading?: Function;
  organizationId?: string;
  isPreFetch?: boolean;
  accountId?: string;
  tenantId?: string;
  unlinked?: boolean;
  rootOnly?: boolean;
  excludeIds?: string[];
  excludeRootId?: string;
  limit?: number;
  autoFocus?: boolean;
  setSelectedAccountOrganization: Function;
  setSelectedOrganization?: Function;
  className?: string;
  validationError?: boolean;
  placeHolder?: string;
  label?: string;
  minInputLengthToTriggerTheDataFetch?: number;
};

export default function OrganizationSelector({
  setIsLoading,
  tenantId,
  accountId,
  unlinked = false,
  rootOnly = false,
  excludeIds = [''],
  excludeRootId,
  limit = 10,
  setSelectedAccountOrganization,
  label,
  minInputLengthToTriggerTheDataFetch = 2,
}: OrganizationSelectorProps) {
  const { t } = useTranslation('Admin');
  const filterData = { accountId, limit, unlinked, excludeIds, rootOnly, excludeRootId };
  const [isPending, setIsPending] = useState(false);
  const [currentComboboxValue, setCurrentComboboxValue] = useState<string>('');
  const [comboboxValueOnPageLoad, setComboboxValueOnPageLoad] = useState<boolean>(false);
  const [accountNodes, setAccountNodes] = useState<LinkAccountInput[]>([]);
  const [accountOrganization, setAccountOrganization] = useState<{
    accountId: string | undefined;
    tenantId: string | undefined;
    name: string;
    parentId: string | undefined;
  }>();

  const [fetchAccountParams, setFetchAccountParams] = useState<FetchAccountsInput>({ accountId });

  const { accounts, error: fetchAccountError } = useFetchAccounts(fetchAccountParams);

  const apolloClient = useApolloClient();

  const currentTenant = (tenant: string | undefined) =>
    CLIENTS[`organization-${tenant || 'eu-1'}` as keyof typeof CLIENTS];

  const onPageLoad = () => {
    if (!accountId) return;

    // TODO: Add BFF Endpoint /ops/accounts/{:id} to fetch this info instead of direct graphql endpoint
    apolloClient
      .query({
        query: GET_ACCOUNT,
        fetchPolicy: 'network-only',
        variables: {
          input: { id: accountId },
        },
        context: { clientName: CLIENTS.account },
      })
      .then(({ data: dataAccount }) => {
        const parentId = dataAccount?.getAccount?.parentId || undefined;
        const name = dataAccount?.getAccount?.name || undefined;

        setAccountOrganization({ accountId, tenantId, name, parentId });
      });
  };

  const onComboboxSelection = () => {
    setCurrentComboboxValue(accountOrganization?.name || '');

    if (accountOrganization?.accountId !== accountId) {
      setSelectedAccountOrganization(accountOrganization);
    }
  };

  const debounceFetchOrganization = useMemo(() => {
    return debounce((data: ListAccountInput) => {
      if ((data?.searchByName || '').length >= minInputLengthToTriggerTheDataFetch) {
        setIsPending(true);

        // Need to override accountId to be undefined - so we can search by name
        setFetchAccountParams({ accountId: undefined, name: data.searchByName || undefined });
      }
    }, 500);
  }, [minInputLengthToTriggerTheDataFetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onPageLoad, []);

  useEffect(onComboboxSelection, [
    accountId,
    accountOrganization,
    accountOrganization?.name,
    accountOrganization?.accountId,
    setSelectedAccountOrganization,
  ]);

  useEffect(() => {
    // Disable pending flag on the combobox once fetchAccountData has changed
    if (!accounts) return;
    setAccountNodes(accounts ?? []);
    setIsPending(false);
  }, [accounts]);

  useEffect(() => {
    // Set combobox value of page loaded organization name - only run once
    if (!currentComboboxValue || comboboxValueOnPageLoad) return;
    debounceFetchOrganization({ searchByName: currentComboboxValue, ...filterData });
    setComboboxValueOnPageLoad(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComboboxValue]);

  const setDashboardOrganization = async (accountId?: string, tenantId?: string, parentId?: string) => {
    if (!(accountId && tenantId)) {
      setAccountOrganization(undefined);
      return;
    }

    // TODO: Add BFF endpoint /ops/organization/{:id}
    // Q: Why do we need this instead of the account call we already do on L83
    const { data: dataOrganization } = await apolloClient.query({
      query: GET_ORGANIZATION_QUERY,
      variables: {
        accountId,
      },
      context: { clientName: currentTenant(tenantId) },
      fetchPolicy: 'network-only',
    });

    if (!dataOrganization?.getOrganization.id) return;
    setAccountOrganization({
      accountId,
      tenantId,
      name: dataOrganization?.getOrganization.name,
      parentId,
    });
  };

  // Set & select account organization
  const selectOrganization = async (accountId?: string, tenantId?: string, parentId?: string) => {
    setIsLoading && setIsLoading(true);

    // fetch and set the organization
    setDashboardOrganization(accountId, tenantId, parentId);

    setIsLoading && setIsLoading(false);
  };

  // Change organization when user selects from the list
  const handleOrgSelect = async (value: string) => {
    if (!value) return;

    // match the value with the data string
    const { id, tenantId, parentId } = accountNodes.find(({ name }) => name === value) || {
      id: undefined,
      tenantId: undefined,
      parentId: undefined,
    };

    if (id && tenantId) {
      await selectOrganization(id, tenantId, parentId || undefined);
    }
  };

  // On click of dropdown or on enter in dropdown
  const onChange = (e: string) => {
    handleOrgSelect(e);
    setCurrentComboboxValue(e);
  };

  const onInputChange = async (value: string, event: ChangeEvent<HTMLInputElement>) => {
    if (event?.type === 'change' && event?.target.value.length >= minInputLengthToTriggerTheDataFetch) {
      // fetch list 500ms after the user finishes typing
      debounceFetchOrganization({ searchByName: value, ...filterData });
    }
  };

  if (fetchAccountError)
    return (
      <section>
        <Typography variant={'body'}>{t('businessUnits.organizationSelect.loadingError')}</Typography>
      </section>
    );

  return (
    <Combobox
      placeholder={t('businessUnits.organizationSelect.findCompany')}
      value={currentComboboxValue}
      onChange={onChange}
      onInputChange={onInputChange}
      loading={isPending}
      size="xs"
      label={label}
      onFocus={(e: any) => e.target.select()} //Select-all input of combobox
    >
      {accountNodes.map(
        ({ name, id }) =>
          name !== '' && (
            <Option
              key={id}
              value={`${name}`}
              title={`${name}`}
              marginX="xs"
              selected={name === currentComboboxValue}
            />
          )
      )}
    </Combobox>
  );
}
