import { createTheme } from '@mui/material';

const fontFamily = 'Galano Grotesque';

export const theme = createTheme({
  typography: {
    fontFamily,
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.0075em',
    },
  },
});
