import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { RequestCallback } from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

export const SUPPORTED_LOCALES = {
  en: 'en_GB',
  fr: 'fr_FR',
  de: 'de_DE',
  es: 'es_ES',
  it: 'it_IT',
  pt: 'pt_PT',
};

const DEFAULT_LANGUAGE = 'en';
const DEFAULT_LOCAL_FALLBACK = 'en_GB';

const getFallbackLocal = (local: string): string => {
  const language = local ? local.slice(0, 2) : DEFAULT_LANGUAGE;
  const fallbackLocale = SUPPORTED_LOCALES[language as keyof typeof SUPPORTED_LOCALES];

  return fallbackLocale ?? DEFAULT_LOCAL_FALLBACK;
};

const loadResources = async (language: string, namespace: string) => {
  return await axios
    .get(
      `${process.env['NEXT_PUBLIC_LOKALISE.baseUrl']}/${process.env['NEXT_PUBLIC_LOKALISE.prefix']}/${language}/${namespace}.json`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(`Something went wrong during loadResources: ${error}`);
    });
};

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  // @ts-ignore
  .init({
    lng: DEFAULT_LOCAL_FALLBACK,
    detection: {
      order: ['htmlTag'],
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    backend: {
      loadPath: '{{lng}}|{{ns}}',
      allowMultiLoading: true,
      request: function (options: any, url: string, payload: string | {}, callback: RequestCallback): void {
        try {
          const [language, namespace] = url.split('|');
          loadResources(getFallbackLocal(language), namespace).then((response) => {
            callback(null, {
              data: response,
              status: 200,
            });
          });
        } catch (e) {
          console.error(`Something went wrong during request: ${e}`);
        }
      },
    },
    initImmediate: false,
    ns: [
      'Accounts',
      'Admin',
      'AppBar',
      'AuthenticationError',
      'AverageSessionRating',
      'Budget',
      'CoacheeChart',
      'CoacheeList',
      'CoacheeSatisfaction',
      'CoacheeSnapshot',
      'CoacheeStatus',
      'CoacheesEmpty',
      'Common',
      'Dashboard',
      'EmptyCoacheeSatisfaction',
      'EmptyFocusArea',
      'Error',
      'FeedbackMessage',
      'FocusArea',
      'FocusAreaMonitoring',
      'IndividualQuestions',
      'Login',
      'Manage',
      'NominateCoachee',
      'ProgramManager',
      'Programs',
      'SessionProgressTimeline',
      'TopStrength',
      'no_filename',
    ],
    fallbackLng: (code: string): string[] => {
      const countryCodeFallback = getFallbackLocal(code);

      // Other languages will fallback to the main country then to english
      // fr_CA -> fr_FR -> en_GB  OR fr -> fr_FR -> en_GB
      // in case there is a missing keys in the selected language we will display the en_GB of that language
      // along side the main language
      if (countryCodeFallback !== DEFAULT_LOCAL_FALLBACK) {
        return [countryCodeFallback, DEFAULT_LOCAL_FALLBACK];
      }

      return [DEFAULT_LOCAL_FALLBACK];
    },
    returnEmptyString: false,
    saveMissing: true,
    react: {
      useSuspense: false,
    },
    missingKeyHandler(lng: string[], ns: string, key: string, fallbackValue: string) {
      console.error(`i18n: Missing key '${key}' on namespace '${ns}'`);
    },
  });

if (typeof window !== 'undefined') {
  const appLanguage = localStorage.getItem('appLanguage');

  if (appLanguage) {
    i18n.changeLanguage(appLanguage);
  }
}

export default i18n;
