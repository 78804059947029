import React, { useState } from 'react';

import { Footer as NovaFooter, FooterSeparator } from '@coachhubio/nova-footer';
import { Link } from '@coachhubio/nova-link';
import { useTranslation } from 'react-i18next';

import { DataAccessPermission } from './DataAccessPermission';

export const footerElementId = 'footer';

const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation('Common');

  const openCopyright = () => {
    window.open('https://citations.coachhub.com', '_blank');
  };

  const openAccessibilityStatement = () => {
    window.open('https://www.coachhub.com/coachhub-accessibility-statement', '_blank');
  };

  return (
    <NovaFooter childrenAlign="end" withCopyright id={footerElementId} gap="2xs">
      <Link href="#" onClick={() => openAccessibilityStatement()}>
        {t('accessibilityStatement')}
      </Link>
      <FooterSeparator />

      <Link href="#" onClick={() => setIsModalVisible(true)}>
        {t('privacy')}
      </Link>

      {isModalVisible && (
        <DataAccessPermission
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      )}

      <FooterSeparator />

      <Link href="#" onClick={() => openCopyright()}>
        {t('copyright')}
      </Link>
    </NovaFooter>
  );
};

export default Footer;
