import { useEffect } from 'react';

import { hotjar } from 'react-hotjar';

import { useHotjarIdentity } from './useHotjarIdentity';

export const useInitializeHotjar = () => {
  const [snippetVersion, trackingCode] = [
    process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION,
    process.env.NEXT_PUBLIC_HOTJAR_TRACKING_CODE,
  ];

  const { hotJarIdentify } = useHotjarIdentity();

  useEffect(() => {
    hotjar.initialize({
      id: trackingCode,
      sv: snippetVersion,
    });

    if (hotjar.initialized()) {
      hotJarIdentify();
    }
  }, [hotJarIdentify]);
};