import { useState } from 'react';

import { OperationVariables, useLazyQuery } from '@apollo/client';
import { LIST_PROGRAMS_FROM_ACCOUNT } from 'apollo/account-api/account';
import { ListProgramsFromAccount } from 'apollo/account-api/types/ListProgramsFromAccount';
import { CLIENTS } from 'apollo/clients';
import { Program } from 'screens/Programs/types';

type UseProgramOptions = {
  accountId: string;
};

const sharedOptions: OperationVariables = {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  context: {
    clientName: CLIENTS.account,
  },
};

export function useProgramsLazy(options: UseProgramOptions) {
  const { accountId } = options;
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const [fetchPrograms, { data, loading, error }] = useLazyQuery<ListProgramsFromAccount>(LIST_PROGRAMS_FROM_ACCOUNT, {
    ...sharedOptions,
    variables: {
      input: {
        accountId,
      },
    },
    onCompleted: () => {
      setIsCompleted(true);
    },
  });

  return {
    fetchPrograms,
    programs: (data?.listPrograms as unknown) as Program[],
    loading,
    error,
    isCompleted,
  };
}
