import React from 'react';
import { CircularProgress } from '@coachhubio/ui-components';

/**
 * Inline styles because this component may be called when
 * MUI or styled-components are not loaded yet (and therefore
 * the styles are not applied).
 */
const style = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export function LoadingPage() {
  return (
    <div style={style}>
      <CircularProgress disableShrink />
    </div>
  );
}