import React from 'react';

import { Toast } from 'components/Toast';
import { ProgramsProvider } from 'screens/Programs/context/ProgramsContext';

import { AppBar } from './components/AppBar';

const AuthenticatedApp = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProgramsProvider>
      <>
        <AppBar />
        {children}
        <Toast />
      </>
    </ProgramsProvider>
  );
};

export default AuthenticatedApp;
