import React from 'react';

import { Column } from '@coachhubio/nova-flex';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';

import { ModalNova } from './Nova/ModalNova';

interface Props {
  onClose: () => void;
}

const handleOptOut = () => {
  const hotjarNoTrackURL = process.env.NEXT_PUBLIC_HOTJAR_NO_TRACK_URL;
  window.open(hotjarNoTrackURL, '_blank');
};

export function DataAccessPermission({ onClose }: Props) {
  const { t } = useTranslation('Common');

  return (
    <ModalNova
      title={t('dataAccessPermissionHotjarTitle')}
      cancelLabel={t('cancel')}
      confirmLabel={t('optout')}
      isConfirmEnabled
      onModalClose={onClose}
      onModalSubmit={handleOptOut}
      withDivider
    >
      <Column>
        <Text>{t('dataAccessPermissionHotjarBody1')}</Text>
        <Text>{t('dataAccessPermissionHotjarBody2')}</Text>
      </Column>
    </ModalNova>
  );
}
