import { useEffect, useState } from 'react';

import { Dictionary, concat, groupBy, sumBy, mapValues, flatten } from 'lodash';
import { Program } from 'screens/Programs/types';

import { Tenant, tenants } from '../../pages/api/tenants';
import { useDashboardBff } from './useDashboardBff';

type CoacheesCount = {
  programId: string;
  coacheesCount: string;
};

export function useCoacheesCountByProgram(programs: Program[]) {
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [coacheesCountByProgram, setCoacheesCountByProgram] = useState<Record<string, number>>({});
  const { apiClient } = useDashboardBff();

  const fetchCoacheesCountByTenant = async (tenant: Tenant) => {
    const coacheesCountResponse = await apiClient(tenant).post<CoacheesCount[]>('/programs/coachees/count', {
      programIds: programs?.map((program) => program.id),
    });

    return coacheesCountResponse.data;
  };

  const fetchCoacheesCount = async () => {
    try {
      setIsFetchingData(true);

      const coacheesCount = concat(flatten(await Promise.all(tenants.map(fetchCoacheesCountByTenant))));

      const countsGroupedByProgramId: Dictionary<CoacheesCount[]> = groupBy(coacheesCount, 'programId');

      if (countsGroupedByProgramId) {
        const coacheeCountsRecord: Record<string, number> = mapValues(countsGroupedByProgramId, (items) =>
          sumBy(items, (item) => Number(item.coacheesCount))
        );

        setCoacheesCountByProgram(coacheeCountsRecord);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    if (programs.length > 0) {
      fetchCoacheesCount();
    }
  }, [programs]);

  return { loading: isFetchingData, coacheesCountByProgram };
}
