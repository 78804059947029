import * as Sentry from '@sentry/nextjs';
import { parse } from 'cookie';
import { GetServerSideProps } from 'next';

import { Roles } from '../utils/Roles';

export function getUser(
  ctx: Parameters<GetServerSideProps>[0]
): null | { id: string; organizationId?: string; accountId?: string; roles: Roles } {
  const { cookie = '' } = ctx.req.headers;
  if (!cookie) {
    return null;
  }
  try {
    const userData = JSON.parse(parse(cookie)[process.env['server.cookieUser']]);
    userData.roles = new Roles(userData.roles);
    return userData;
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
