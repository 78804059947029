import React, { ReactNode } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import { CoachHubAuthProvider } from './CoachHubAuthProvider';

export const CoachHubIdentityProvider = ({
  accessToken,
  children,
  user,
}: {
  accessToken: string;
  children: ReactNode;
  user: any;
}) => {
  const auth0domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
  if (!auth0domain) {
    throw new Error('Missing config value for AUTH0_DOMAIN');
  }

  const auth0clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
  if (!auth0clientId) {
    throw new Error('Missing config value for AUTH0_CLIENT_ID');
  }

  const auth0redirectUri = process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI;
  if (!auth0redirectUri) {
    throw new Error('Missing config value for AUTH0_REDIRECT_URI');
  }

  return (
    <Auth0Provider
      domain={auth0domain}
      clientId={auth0clientId}
      redirectUri={auth0redirectUri}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <CoachHubAuthProvider accessToken={accessToken} user={user}>
        {children}
      </CoachHubAuthProvider>
    </Auth0Provider>
  );
};
