import { useEffect, useState } from 'react';

import { redirection } from 'redirection';
import { NextRouter, useRouter } from 'next/router';

import { useFlags } from './useFlags';

export const useRedirects = () => {
  const [initialized, setInitialized] = useState(false);

  const flags = useFlags();
  const router = useRouter();

  const redirect = async (router: NextRouter) => {
    const enableCoacheeList = flags.enableCoacheeList;
    const showInsightsPlus = flags.showInsightsPlus;

    const path = redirection({
      enableCoacheeList,
      showInsightsPlus,
    }).get(router.pathname);

    if (path) {
      router.push(path?.destination);
    }

    setInitialized(true);
  };

  useEffect(() => {
    redirect(router);
  }, [router]);

  return { initialized };
};