import * as Sentry from '@sentry/nextjs';
import router from 'next/router';

const loginUrl = '/api/login';
const logoutUrl = '/api/logout';

const performLogin = (username: string, password: string) =>
  performAuthentication({
    username,
    password,
  });

const performTokenExchange = (accessToken: string, silent = false) =>
  performAuthentication({ subject_token: accessToken }, silent);

const performAuthentication = async (body: object, silent = false) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  let token;
  try {
    const userApiResponse = await fetch(loginUrl, requestOptions);
    if (userApiResponse.status >= 500) {
      throw new Error('SERVER_UNAVAILABLE');
    } else if (userApiResponse.status !== 200) {
      throw new Error('INVALID_CREDENTIALS');
    } else {
      token = (await userApiResponse.text()).replaceAll('"', '');
    }
  } catch (error) {
    if (!silent) {
      throw error;
    }
  }

  if (router.pathname === '/login') {
    router.push('/');
    return token;
  }

  if (!silent) {
    if (router.pathname === '/') {
      // drop auth0 query params, otherwise the router will show an error upon reload
      // because it's called with the authorization code as query param
      router.push({ pathname: router.pathname });
    } else {
      router.push({ pathname: router.pathname, query: router.query });
    }
  }

  return token;
};

const performLogout = async (): Promise<void> => {
  try {
    await fetch(logoutUrl, { method: 'POST' });
  } catch (error) {
    Sentry.captureException(error);
    console.error('Logout Error', error);
  }
};

export { performLogin, performTokenExchange, performLogout };
