import * as Sentry from '@sentry/nextjs';
import axios, { AxiosInstance } from 'axios';
import { customHeaders } from 'customHeaders';

type Props = {
  baseURL: string | undefined;
  headers: any;
  timeout: number;
};

export const createAxiosInstance = ({ baseURL, headers, timeout = 5000 }: Props): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    timeout,
    headers: {
      ...customHeaders,
      ...headers,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.headers) {
        const traceId = error.response.headers['x-ch-trace-id'] ?? undefined;
        Sentry.setTag('trace-id', traceId);
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
