import { dashboardBffApiClient } from 'clients';

import { Tenant } from '../../pages/api/tenants';
import { AuthRole, useAccessToken as getAccessTokenFromLocalStorage, useUser } from '../context/AuthContext';

export const useDashboardBff = () => {
  const { roles, tenantId: usersTenantId } = useUser();

  return {
    apiClient: (overriddenTenantId?: Tenant) => {
      const isCSM = roles.has(AuthRole.OPERATIONS);
      const basePath = isCSM ? 'operations-api' : 'customer-api';
      const token = getAccessTokenFromLocalStorage();

      return dashboardBffApiClient(token, overriddenTenantId ?? usersTenantId, basePath);
    },
  };
};
