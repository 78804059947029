import { useEffect, useState } from 'react';

import { AuthRole, useUser } from 'context/AuthContext';
import { useDashboardBff } from 'hooks/useDashboardBff';
import { useFlags } from 'hooks/useFlags';
import { useProgramsLazy } from 'hooks/usePrograms';
import { Program } from 'screens/Programs/types';

import { useLocale } from '../useLocale';

export type Response = {
  loading: boolean;
  programs: Program[];
};

export default function useFetchPrograms(): Response {
  const locale = useLocale();
  const { accountId, roles } = useUser();
  const { enableCoachhubFrameworkProgramPage } = useFlags();

  const { apiClient: dashboardBffApiClient } = useDashboardBff();
  const [loadingFromDashboardBff, setLoadingFromDashboardBff] = useState<boolean>(true);
  const [programsFromDashboardBff, setProgramsFromDashboardBff] = useState<Program[]>([]);

  const {
    loading: loadingFromAccountApi,
    programs: programsFromAccountApi,
    fetchPrograms: fetchFromAccountApi,
  } = useProgramsLazy({ accountId });

  useEffect(() => {
    if (accountId) {
      if (enableCoachhubFrameworkProgramPage) {
        let url = 'programs';

        if (roles.has(AuthRole.OPERATIONS)) {
          // If it's a CPM, we get the programs from the selected account
          url += `?accountId=${accountId}`;
        }

        dashboardBffApiClient()
          .get<Program[]>(url, {
            headers: {
              'Accept-Language': locale,
            },
          })
          .then(({ data }) => setProgramsFromDashboardBff(data))
          .catch(console.error)
          .finally(() => setLoadingFromDashboardBff(false));
      } else {
        fetchFromAccountApi();
      }
    }
  }, [accountId, fetchFromAccountApi]);

  return {
    loading: enableCoachhubFrameworkProgramPage ? loadingFromDashboardBff : loadingFromAccountApi,
    programs: enableCoachhubFrameworkProgramPage ? programsFromDashboardBff : programsFromAccountApi,
  };
}
