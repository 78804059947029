import React from 'react';

import { Button } from '@coachhubio/nova-button';
import { UserPlusIcon } from '@coachhubio/nova-icon';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useProgramsContext } from 'screens/Programs/context/ProgramsContext';

export const NominateAction = () => {
  const router = useRouter();
  const { t } = useTranslation('Programs');
  const { canNotNominateCoachee, programs = [] } = useProgramsContext();

  return (
    <>
      {!canNotNominateCoachee && programs.length > 0 && (
        <Button
          size="s"
          onClick={() => {
            router.push('/nominate-coachee');
          }}
          variant="cta"
        >
          <UserPlusIcon size="m" />

          {t('nominateButton')}
        </Button>
      )}
    </>
  );
};
