/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountCoacheeNominationAccess {
  ALL = 'ALL',
  ROOT_ONLY = 'ROOT_ONLY',
}

export enum AccountEngagementType {
  COUNT = 'COUNT',
  RANGE = 'RANGE',
}

export enum AuthRoles {
  BI = 'BI',
  COACH = 'COACH',
  COACHEE = 'COACHEE',
  CUSTOMER = 'CUSTOMER',
  OPERATIONS = 'OPERATIONS',
}

export enum CoacheeEngagementTimeframe {
  LAST_2_MONTHS = 'LAST_2_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_9_MONTHS = 'LAST_9_MONTHS',
}

export enum CoachingClassification {
  CHANGE_MANAGEMENT = 'CHANGE_MANAGEMENT',
  COACHING_HUBS = 'COACHING_HUBS',
  DE_I = 'DE_I',
  EXECUTIVE = 'EXECUTIVE',
  STANDARD = 'STANDARD',
  TRY_COACHHUB = 'TRY_COACHHUB',
  WELLBEING = 'WELLBEING',
  PRO_BONO = 'PRO_BONO',
}

export enum CoachingStatusValue {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DEPRECATED = 'DEPRECATED',
  INVITED = 'INVITED',
  MATCHED = 'MATCHED',
  MATCHING = 'MATCHING',
  NO_RESPONSE = 'NO_RESPONSE',
  PAUSED = 'PAUSED',
}

export enum CoachingType {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
}

export enum ContractGrantType {
  USAGE = 'USAGE',
}

export enum PriceClassification {
  COACHING_HUBS_STANDARD = 'COACHING_HUBS_STANDARD',
  CO_DEVELOPMENT_HUBS_STANDARD_4_PARTICIPANTS = 'CO_DEVELOPMENT_HUBS_STANDARD_4_PARTICIPANTS',
  CO_DEVELOPMENT_HUBS_STANDARD_5_PARTICIPANTS = 'CO_DEVELOPMENT_HUBS_STANDARD_5_PARTICIPANTS',
  CO_DEVELOPMENT_HUBS_STANDARD_6_PARTICIPANTS = 'CO_DEVELOPMENT_HUBS_STANDARD_6_PARTICIPANTS',
  EXECUTIVE = 'EXECUTIVE',
  PRO_BONO = 'PRO_BONO',
  STANDARD = 'STANDARD',
  STANDARD_WITH_AI = 'STANDARD_WITH_AI',
  EXECUTIVE_WITH_AI = 'EXECUTIVE_WITH_AI',
  FLASH_WITH_AI = 'FLASH_WITH_AI',
  PRO_BONO_WITH_AI = 'PRO_BONO_WITH_AI',
  UNKNOWN = 'UNKNOWN',
}

export enum ProgramSubType {
  COACHING_HUBS = 'COACHING_HUBS',
  CO_DEVELOPMENT_HUBS = 'CO_DEVELOPMENT_HUBS',
}

export enum ProgramType {
  COLLECTIVE = 'COLLECTIVE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CanCreateProgramInput {
  accountRootId: string;
  name: string;
}

export interface CreateCoachBriefingInput {
  fileId?: string | null;
  programId?: string | null;
}

export interface CreateProgramInput {
  accountGrants: string[];
  accountId: string;
  coachId?: string | null;
  coachingClassification: CoachingClassification;
  description?: string | null;
  duration: number;
  enableLineManagerInvolvement?: boolean | null;
  name: string;
  priceClassification: PriceClassification;
  sessions?: number | null;
  subType?: ProgramSubType | null;
  type?: ProgramType | null;
}

export interface DateRangeInput {
  from?: any | null;
  to?: any | null;
}

export interface DeleteCoachBriefingInput {
  id: string;
}

export interface EnableCustomContractGrantsInput {
  contractId: string;
}

export interface GetAccountInput {
  id: string;
}

export interface GetAvailableSeatsInput {
  coacheeAccountId: string;
  coachingType: CoachingType;
  currentUserAccountId?: string | null;
  duration: number;
  priceClassification: PriceClassification;
  programId?: string | null;
}

export interface GetCoachBriefingInput {
  programId: string;
}

export interface GetContractInput {
  id: string;
}

export interface GetProgramInput {
  id: string;
}

export interface LinkAccountInput {
  id: string;
  name?: string;
  parentId?: string;
  tenantId?: string;
}

export interface ListAccountInput {
  accountId?: string | null;
  accountIds?: (string | null)[] | null;
  excludeIds?: (string | null)[] | null;
  excludeRootId?: string | null;
  limit?: number | null;
  rootOnly?: boolean | null;
  searchByExactName?: string | null;
  searchByName?: string | null;
  unlinked?: boolean | null;
  updatedSince?: any | null;
}

export interface ListAllContractsInput {
  accountId: string;
  includeExpired?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  search?: string | null;
}

export interface ListCoachingStatusValuesInput {
  accountId: string;
}

export interface ListContractsInput {
  accountId?: string | null;
  hubspotDealId?: string | null;
  hubspotDealNumber?: string | null;
  includeExpired?: boolean | null;
  usingAccountIds?: (string | null)[] | null;
}

export interface ListMemberInput {
  accountId: string;
  accountIds?: (string | null)[] | null;
  end?: DateRangeInput | null;
  engagementTimeframe?: CoacheeEngagementTimeframe | null;
  nominated?: DateRangeInput | null;
  programId?: string | null;
  role?: AuthRoles | null;
  search?: string | null;
  showOnlyStuckCoachees?: boolean | null;
  sortBy?: string | null;
  sortDirection?: SortDirection | null;
  start?: DateRangeInput | null;
  status?: (CoachingStatusValue | null)[] | null;
}

export interface ListProgramManagersInput {
  ids?: (string | null)[] | null;
}

export interface ListProgramsInput {
  accountId?: string | null;
  coachId?: string | null;
  programIds?: string[] | null;
  type?: ProgramType | null;
}

export interface PreviewStopCoachingInput {
  userId: string;
}

export interface UnlinkAccountInput {
  id: string;
}

export interface UpdateAccountConfigInput {
  accountId: string;
  coacheeNominationAccess?: AccountCoacheeNominationAccess | null;
  engagementType?: AccountEngagementType | null;
  isManualMappingEnabled?: boolean | null;
}

export interface UpdateCoachBriefingInput {
  fileId: string;
  id: string;
}

export interface UpdateContractGrantInput {
  addUsageGrants?: string[] | null;
  contractId?: string | null;
  removeUsageGrants?: string[] | null;
}

export interface UpdateProgramInput {
  accountId?: string | null;
  addAccountGrants?: (string | null)[] | null;
  coachId?: string | null;
  coachingClassification?: CoachingClassification | null;
  description?: string | null;
  duration?: number | null;
  enableLineManagerInvolvement?: boolean | null;
  id: string;
  name?: string | null;
  priceClassification?: PriceClassification | null;
  removeAccountGrants?: (string | null)[] | null;
  sessions?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
