import React from 'react';

import { getAliasColorValue } from '@coachhubio/nova-colors';
import { lightTheme } from '@coachhubio/nova-theme';
import { Button, makeStyles, Typography, useTheme } from '@coachhubio/ui-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import FlagDude from './dude-with-flag.png';

interface ScreensErrorProps {
  type?: '404' | 'default';
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: getAliasColorValue(lightTheme['text-color-link-default']),
  },
  headline: {
    fontSize: '45px',
    margin: theme.spacing(8),
  },
  image: {
    marginBottom: theme.spacing(5),
    width: 250,
  },
  button: { marginTop: theme.spacing(5) },
}));

export const ScreensError = (props: ScreensErrorProps) => {
  const type = props.type || 'default';
  const { t } = useTranslation('Error');
  const classes = useStyles();
  const router = useRouter();
  const {
    brandPalette: {
      deepBlue: { '400': fontColor },
    },
  } = useTheme();

  const patternId = 'dudePattern0';
  const imageId = 'dudeImage1';

  const backHome = () => router.push('/');

  return (
    <div className={classes.root}>
      <Typography data-testid={`error-${type}`} variant="h1" className={classes.headline}>
        {t(`${type}Title`)}
      </Typography>
      <svg className={classes.image} viewBox="0 0 378 494" fill="none">
        <path fill={`url(#${patternId})`} d="M0 0H377.822V494H0z" />
        {type !== 'default' && (
          <text
            transform="rotate(34.215 98.176 457.657)"
            fill={fontColor}
            style={{
              whiteSpace: 'pre',
            }}
            fontFamily="Galano Grotesque"
            fontSize={50}
            fontWeight={500}
            letterSpacing="-0.634091px"
          >
            <tspan x={1.26485} y={49.4437}>
              {type}
            </tspan>
          </text>
        )}
        <defs>
          <pattern id={patternId} patternContentUnits="objectBoundingBox" width={1} height={1}>
            <use xlinkHref={`#${imageId}`} transform="scale(.00116 .0009)" />
          </pattern>
          <image id={imageId} width={859} height={1122} xlinkHref={FlagDude} />
        </defs>
      </svg>
      <div>{t(`${type}Message`)}</div>
      <div>{t(`tryAgain`)}</div>
      <Button className={classes.button} onClick={backHome}>
        {t('back')}
      </Button>
    </div>
  );
};
