import { ReactElement } from 'react';

import { Button } from '@coachhubio/nova-button';
import {
  LargeModal,
  MediumModal,
  Modal,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  ModalWrapper,
} from '@coachhubio/nova-modal';
import { paddingTop, paddingX, paddingY } from '@coachhubio/nova-spaces';
import { Spinner } from '@coachhubio/nova-spinner';
import styled from 'styled-components';

interface IFooter {
  onModalClose: () => void;
  onModalSubmit: () => void;
  cancelLabel?: string | ReactElement;
  confirmLabel?: string | ReactElement;
  isConfirmEnabled?: boolean;
  isConfirmLoading?: boolean;
}

const StyledModalFooter = styled.div`
  width: 100%;
`;

const StyledModalFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export function ModalFooter({
  onModalClose,
  onModalSubmit,
  cancelLabel,
  confirmLabel,
  isConfirmEnabled = true,
  isConfirmLoading = false,
}: IFooter): ReactElement {
  return (
    <StyledModalFooter>
      <StyledModalFooterContainer>
        {cancelLabel && (
          <Button onClick={onModalClose} variant="secondary">
            {cancelLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button onClick={onModalSubmit} disabled={!isConfirmEnabled} variant="cta">
            {confirmLabel} {isConfirmLoading ? <Spinner /> : <></>}
          </Button>
        )}
      </StyledModalFooterContainer>
    </StyledModalFooter>
  );
}

interface IModalNova {
  closeOnOutsideClick?: boolean;
  onModalClose: () => void;
  onModalSubmit: () => void;
  children: ReactElement;
  title: string | ReactElement;
  cancelLabel?: string | ReactElement;
  confirmLabel?: string | ReactElement;
  isConfirmEnabled?: boolean;
  isConfirmLoading?: boolean;
  withDivider?: boolean;
  // hideAndKeepContext for transition modals to keep context between them
  hideAndKeepContext?: boolean;
  variant?: 'medium' | 'small' | 'large';
}

const StyledLargeModal = styled(LargeModal)`
  // max value for z-index
  z-index: 999995;
`;

const StyledMediumModal = styled(MediumModal)`
  // max value for z-index
  z-index: 999995;
  text-align: left;

  ${ModalTitle} {
    font-size: 1.25rem;
  }

  ${ModalContentContainer} {
    overflow-y: inherit;
  }

  ${ModalWrapper} {
    @media (max-height: 700px) {
      overflow-y: auto;
    }
  }
`;

const StyledSmallModal = styled(Modal)`
  // max value for z-index
  z-index: 999995;

  ${ModalWrapper} {
    min-width: 480px;

    ${ModalHeaderContainer} {
      border-bottom: 1px solid #c6c7d2;
      ${paddingY('base')}
      ${paddingX('l')}

      ${ModalTitle} {
        padding: 0;
      }
    }

    ${ModalContentContainer} {
      ${paddingX('l')}
    }

    ${ModalFooterContainer} {
      ${paddingTop('base')}
      ${paddingX('l')}
    }
  }
`;

export const ModalNova = ({
  closeOnOutsideClick,
  title,
  onModalClose,
  onModalSubmit,
  children,
  cancelLabel,
  withDivider,
  confirmLabel,
  hideAndKeepContext,
  variant = 'medium',
  isConfirmEnabled,
  isConfirmLoading,
}: IModalNova) => {
  return (
    <div style={{ visibility: hideAndKeepContext ? 'hidden' : 'visible' }}>
      {variant === 'medium' && (
        <StyledMediumModal
          closeOnOutsideClick={closeOnOutsideClick}
          withTitleDivider={withDivider}
          withFooterDivider={withDivider}
          footer={
            <ModalFooter
              confirmLabel={confirmLabel}
              isConfirmEnabled={isConfirmEnabled}
              isConfirmLoading={isConfirmLoading}
              cancelLabel={cancelLabel}
              onModalClose={onModalClose}
              onModalSubmit={onModalSubmit}
            />
          }
          disableBodyScroll={false}
          onClose={onModalClose}
          title={title}
        >
          {children}
        </StyledMediumModal>
      )}

      {variant === 'small' && (
        <StyledSmallModal
          closeOnOutsideClick={closeOnOutsideClick}
          type="small"
          withTitleDivider={withDivider}
          withFooterDivider={withDivider}
          footer={
            <ModalFooter
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
              onModalClose={onModalClose}
              onModalSubmit={onModalSubmit}
              isConfirmEnabled={isConfirmEnabled}
              isConfirmLoading={isConfirmLoading}
            />
          }
          disableBodyScroll={false}
          onClose={onModalClose}
          title={title}
          withCloseButton={false}
        >
          {children}
        </StyledSmallModal>
      )}

      {variant === 'large' && (
        <StyledLargeModal
          closeOnOutsideClick={closeOnOutsideClick}
          withTitleDivider={withDivider}
          withFooterDivider={withDivider}
          footer={
            <ModalFooter
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
              onModalClose={onModalClose}
              onModalSubmit={onModalSubmit}
              isConfirmEnabled={isConfirmEnabled}
              isConfirmLoading={isConfirmLoading}
            />
          }
          disableBodyScroll={false}
          onClose={onModalClose}
          title={title}
        >
          {children}
        </StyledLargeModal>
      )}
    </div>
  );
};
