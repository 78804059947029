import React from 'react';

import './global.css';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import AuthenticatedApp from '../src/AuthenticatedApp';
import { LoadingPage } from '../src/components/LoadingPage';
import { UserInput } from '../src/context/AuthContext';
import { useInitializeHotjar } from '../src/hooks/useInitializeHotjar';
import { useInitializeLaunchDarkly } from '../src/hooks/useInitializeLaunchDarkly';
import { useInitializeMatomo } from '../src/hooks/useInitializeMatomo';
import { useInitializeSentry } from '../src/hooks/useInitializeSentry';
import { useRedirects } from '../src/hooks/useRedirects';
import LoadingApp from '../src/LoadingApp';
import { Providers } from '../src/Providers';
import { CoachHubIdentityProvider } from '../src/providers/CoachHubIdentityProvider';
import { ScreensError } from '../src/screens/Error/Error';
import { getAccessToken } from '../src/server/getAccessToken';
import { getUser } from '../src/server/getUser';
import { ZendeskWidget } from '../src/utils/ZendeskWidget';

type ProvidersProps = {
  children: React.ReactNode;
  user?: UserInput;
  accessToken: string;
  hasSession: boolean;
  error: any;
};

interface ErrorWithType extends Error {
  type: string;
}

export function MyApp({
  err,
  props,
  pageProps,
  Component,
}: {
  err: ErrorWithType;
  props: ProvidersProps;
  pageProps: any;
  Component: any;
}) {
  const isNotFound =
    (props.error?.type === 'NotFound' || (props.hasSession && pageProps?.error?.type === 'NotFound')) ?? false;

  if (isNotFound) {
    return (
      <Providers {...props}>
        <ScreensError type="404" />
      </Providers>
    );
  }

  return (
    <CoachHubIdentityProvider accessToken={props.accessToken} user={props.user}>
      <AppWithLDProviderInitialized>
        <AppWithRedirectsInitialized>
          <AsyncInitializers />

          <Providers>
            <AuthenticatedApp>
              <LoadingApp>
                <Component {...pageProps} err={err} />
              </LoadingApp>
            </AuthenticatedApp>
          </Providers>
        </AppWithRedirectsInitialized>
      </AppWithLDProviderInitialized>
    </CoachHubIdentityProvider>
  );
}

const AppWithLDProviderInitialized = ({ children }: any) => {
  const { initialized } = useInitializeLaunchDarkly();
  return initialized ? children : <LoadingPage />;
};

const AppWithRedirectsInitialized = ({ children }: any) => {
  const { initialized } = useRedirects();
  return initialized ? children : <LoadingPage />;
};

const AsyncInitializers = () => {
  useInitializeSentry();
  useInitializeMatomo();
  useInitializeHotjar();

  return <ZendeskWidget />;
};

const AppWithLDProviderDefined = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY as string,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  user: {
    anonymous: true,
  },
  options: {
    sendLDHeaders: false,
    streaming: false,
    bootstrap: 'localStorage',
    allAttributesPrivate: true,
  },
  deferInitialization: true,
})(MyApp as any);

// @ts-ignore
AppWithLDProviderDefined.getInitialProps = async ({ ctx }) => {
  const accessToken = getAccessToken(ctx);
  const user = getUser(ctx);
  const props: any = {};

  if (ctx.pathname?.indexOf('404') > -1) {
    props.error = {
      type: 'NotFound',
    };
  }

  if (!accessToken || !user) {
    return { props: { ...props, hasSession: false } };
  }
  return { props: { ...props, hasSession: true, user, accessToken } };
};

export default AppWithLDProviderDefined;