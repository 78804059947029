import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { getAliasColorValue } from '@coachhubio/nova-colors';
import { lightTheme } from '@coachhubio/nova-theme';
import { Button, CircularProgress, makeStyles, Typography } from '@coachhubio/ui-components';
import { useTranslation } from 'react-i18next';
import { performLogout } from 'utils/authentication';
import { getCookie } from 'utils/cookies';

import CoachhubLogo from '../../assets/coachhub-logo.svg';
import Ghost from '../../assets/ghost.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: getAliasColorValue(lightTheme['brand-color-primary']),
    paddingTop: theme.spacing(10),
  },
  logo: {
    width: '220px',
    marginBottom: theme.spacing(8),
  },
  image: {
    width: '280px',
    marginBottom: theme.spacing(8),
  },
  title: {
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: theme.spacing(7),
  },
}));

export const AuthenticationError = () => {
  const { t } = useTranslation('AuthenticationError');
  const classes = useStyles();
  const { logout: auth0logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const USER_COOKIE = process.env.NEXT_PUBLIC_COOKIE_USER;
  const [isEmptyUserCookie] = useState(USER_COOKIE && !getCookie(USER_COOKIE));

  const logout = async () => {
    setIsLoading(true);
    try {
      await performLogout();
      auth0logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI });
    } catch (err) {
      setIsLoading(false);
    }
  };

  // when the user cookies is not available to automatically logout
  useEffect(() => {
    if (isEmptyUserCookie) {
      auth0logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI });
    }
  }, [auth0logout, isEmptyUserCookie]);

  return !isEmptyUserCookie ? (
    <div className={classes.root}>
      <img src={CoachhubLogo} alt="CoachHub" className={classes.logo} />
      <img src={Ghost} alt="spaceship with a ghost" className={classes.image} />
      <Typography variant="h1" className={classes.title}>
        {t('title')}
      </Typography>
      <Typography variant="subtitle" className={classes.subtitle}>
        {t('subtitle')}
      </Typography>
      <Button className={classes.button} disabled={isLoading} onClick={logout}>
        {isLoading ? <CircularProgress /> : t('returnToLogin')}
      </Button>
    </div>
  ) : (
    <></>
  );
};
