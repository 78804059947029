import * as Sentry from '@sentry/nextjs';
import { AxiosInstance } from 'axios';
import { createAxiosInstance } from 'axiosConfig';
import { GraphQLClient } from 'graphql-request';

import { getSdk, SdkFunctionWrapper } from './graphql/sdk';

// @ts-ignore
const errorMiddleware: SdkFunctionWrapper = async <T>(action: () => Promise<T>): Promise<T> => {
  try {
    return await action();
  } catch (error: any) {
    if (error.response) {
      const traceId = error.response.headers.get('x-ch-trace-id') ?? undefined;
      Sentry.setTag('trace-id', traceId);
      Sentry.captureException(error);
    }

    throw error;
  }
};

export const organizationApiClient = (accessToken: string, tenantId?: string) => {
  const urlEU1 = process.env['apiHosts.organization']
    ? process.env['apiHosts.organization'] + '/graphql'
    : process.env.NEXT_PUBLIC_ORG_API + '/graphql';

  const urlUS1 = process.env['apiHosts.organizationUS1']
    ? process.env['apiHosts.organizationUS1'] + '/graphql'
    : process.env.NEXT_PUBLIC_ORG_API_US_1 + '/graphql';
  const url = tenantId && tenantId === 'us-1' ? urlUS1 : urlEU1;

  return getSdk(new GraphQLClient(url, { headers: { Authorization: accessToken } }), errorMiddleware);
};

export const accountApiClient = (accessToken: string) => {
  const url = process.env['apiHosts.account']
    ? process.env['apiHosts.account'] + '/graphql'
    : process.env.NEXT_PUBLIC_ACCOUNT_API + '/graphql';

  return new GraphQLClient(url, {
    headers: { Authorization: accessToken },
  });
};

export const coachingApiClient = (accessToken: string) =>
  createAxiosInstance({
    baseURL: process.env['apiHosts.coaching'],
    timeout: 1000,
    headers: { Authorization: accessToken },
  });

export const userApiClient = (accessToken: string, tenantId?: string) => {
  const urlUserEU1 = process.env['apiHosts.user'];
  const urlUserUS1 = process.env['apiHosts.userUS1'];
  const url = tenantId && tenantId === 'us-1' ? urlUserUS1 : urlUserEU1;

  return createAxiosInstance({
    baseURL: url,
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};

export const coacheeApiClient = (accessToken: string, tenantId?: string) => {
  const urlCoacheeEU1 = process.env['apiHosts.coacheeEU1'];
  const urlCoacheeUS1 = process.env['apiHosts.coacheeUS1'];
  const url = tenantId && tenantId === 'us-1' ? urlCoacheeUS1 : urlCoacheeEU1;

  return createAxiosInstance({
    baseURL: url,
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};

export const sessionApiClient = (accessToken: string) =>
  createAxiosInstance({
    baseURL: process.env['apiHosts.session'],
    timeout: 5000,
    headers: { Authorization: accessToken },
  });

export const coachingGatewayClient = (accessToken: string) =>
  getSdk(
    new GraphQLClient(process.env['apiHosts.coachingGateway'] + '/graphql', {
      headers: { Authorization: accessToken },
    }),
    errorMiddleware
  );

export const customerApiClient = (accessToken: string, tenantId: string) => {
  const urlUserEU1 = process.env['apiHosts.customerService']
    ? process.env['apiHosts.customerService']
    : process.env.NEXT_PUBLIC_CUSTOMER_API;
  const urlUserUS1 = process.env['apiHosts.customerServiceUS1']
    ? process.env['apiHosts.customerServiceUS1']
    : process.env.NEXT_PUBLIC_CUSTOMER_API_US_1;
  const url = tenantId && tenantId === 'us-1' ? urlUserUS1 : urlUserEU1;

  return createAxiosInstance({
    baseURL: url,
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};
export const lineManagerApiClient = (accessToken: string, tenantId: string) => {
  const urlLineManagerEU1 = process.env['apiHosts.lineManagerEU1'];

  const urlLineManagerUS1 = process.env['apiHosts.lineManagerUS1'];

  const baseURL = tenantId && tenantId === 'us-1' ? urlLineManagerUS1 : urlLineManagerEU1;

  return createAxiosInstance({
    baseURL,
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};

export const zendeskBffClient = (accessToken: string, tenantId: string) => {
  const urlZendeskBffEU1 = process.env['apiHosts.coachingWebBFFEU1'];
  const urlZendeskBffUS1 = process.env['apiHosts.coachingWebBFFUS1'];

  const baseURL = tenantId && tenantId === 'us-1' ? urlZendeskBffUS1 : urlZendeskBffEU1;
  return createAxiosInstance({
    baseURL,
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};

export const dataAndInsightApiClient = (accessToken: string) => {
  return createAxiosInstance({
    baseURL: process.env['apiHosts.dataAndInsight'],
    timeout: 5000,
    headers: { Authorization: accessToken },
  });
};

export const dashboardBffApiClient = (accessToken: string, tenantId?: string, basePath?: string): AxiosInstance => {
  let url =
    tenantId === 'us-1'
      ? process.env['apiHosts.dashboardBFFUS'] ?? process.env.NEXT_PUBLIC_DASHBOARD_BFF_US_1
      : process.env['apiHosts.dashboardBFF'] ?? process.env.NEXT_PUBLIC_DASHBOARD_BFF;

  if (basePath) {
    url += `/${basePath}`;
  } else {
    url += '/';
  }

  return createAxiosInstance({
    baseURL: url,
    timeout: 15000,
    headers: { Authorization: accessToken },
  });
};
