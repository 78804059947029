import { useRouter } from 'next/router';

export async function fetchApi(...args: Parameters<typeof fetch>) {
  const res = await fetch(...args);
  return res;
}

export function useFetchApi() {
  const router = useRouter();
  return {
    fetchApi: async (...args: Parameters<typeof fetch>) => {
      const res = await fetchApi(...args);
      if (res.status === 401) {
        router.reload();
      } else {
        return res;
      }
    },
  };
}
