import { AuthRole, matchRoles } from '@coachhubio/auth-role';

export class Roles {
  constructor(private roles: string[]) {}
  has(...allowedRoles: AuthRole[]) {
    const roles = this.roles instanceof Roles ? this.roles.get() : this.roles;
    return matchRoles(allowedRoles, roles);
  }
  get() {
    return this.roles;
  }
  toJSON() {
    return this.roles;
  }
}
