import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';

import { useUser } from '../context/AuthContext';

export function useHotjarIdentity() {
  const { id: userId, roles } = useUser();
  const { i18n } = useTranslation();
  return {
    hotJarIdentify(userAttributes?: any) {
      return hotjar.identify(userId, { locale: i18n.language, roles: roles.get().sort().join('_'), ...userAttributes });
    },
  };
}
