import React, { ReactNode, Ref } from 'react';

import { createStyles, Link as MuiLink, LinkProps as MuiLinkProps, makeStyles } from '@coachhubio/ui-components';
import clsx from 'clsx';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { NextRouter, useRouter } from 'next/router';

interface PickedNextLinkProps extends Pick<NextLinkProps, 'as' | 'prefetch' | 'scroll' | 'replace' | 'passHref'> {}

export type CheckIsActiveFn = (router: NextRouter, link: NextLinkProps) => boolean;

export type LinkProps = PickedNextLinkProps &
  MuiLinkProps & {
    children: ReactNode;
    className?: string;
    activeClassName?: string;
    checkIsActive?: (linkProps: LinkProps) => boolean;
    innerRef?: () => any;
    onClick?: () => any;
    href?: NextLinkProps['href'];
  };

export function useCheckIsActive(
  checkFn: CheckIsActiveFn = (router, link) => router.pathname === link.href
): (linkProps: LinkProps) => boolean {
  const router = useRouter() || {};
  return (linkProps: LinkProps) => checkFn(router, linkProps as any);
}

interface NextComposedProps extends PickedNextLinkProps {
  as?: string;
  children: ReactNode;
  href: string | object;
  prefetch?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
    },
  })
);

// In order of merging the Link component of material-ui
// and the Link component of Next.js, we have to wrap the
// Next Link inside a forwardRef.
// Source: https://github.com/mui-org/material-ui/blob/master/examples/nextjs/src/Link.js
const NextComposed = React.forwardRef((props: NextComposedProps, ref: Ref<any>) => {
  const { as, href, prefetch, scroll, replace, passHref, children, ...rest } = props;

  return (
    <NextLink href={href} as={as} prefetch={prefetch} scroll={scroll} replace={replace} passHref={passHref} {...rest}>
      {children}
    </NextLink>
  );
});

export function Link({ className, activeClassName = '', checkIsActive, innerRef, ...rest }: LinkProps) {
  const classes = useStyles();

  const checkIsActiveDefault = useCheckIsActive();
  const isActive = checkIsActive !== undefined ? checkIsActive(rest as any) : checkIsActiveDefault(rest as any);

  const Component = (rest.href ? NextComposed : undefined) as any;
  const classNames = clsx(classes.link, {}, className, { [activeClassName]: isActive });
  // @ts-ignore
  return <MuiLink component={Component} className={classNames} ref={innerRef} {...rest} />;
}
