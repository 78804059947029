import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useInterval } from '../hooks/useInterval';
import { LoadingPage } from 'components/LoadingPage';
import { AuthProvider } from '../context/AuthContext';
import { setAccessToken } from '../utils/accessToken';
import { isTokenStillValid } from 'utils/helper';
import { AuthenticationError } from 'screens/Error/AuthenticationError';
import { performTokenExchange } from '../utils/authentication';

const HEARTBEAT_INTERVAL_FALLBACK = '60';

const getDelay = () => {
  const intervalInSeconds = parseInt(
    process.env.NEXT_PUBLIC_TOKEN_REFRESH_INTERVAL_IN_SECONDS || HEARTBEAT_INTERVAL_FALLBACK,
    10
  );
  return intervalInSeconds * 1000;
};

const MemoizedAuthProvider = React.memo(({ children, user }: any) => (
  <AuthProvider user={user}>{children}</AuthProvider>
));

export const CoachHubAuthProvider = ({
  accessToken,
  user,
  children,
}: {
  accessToken: string;
  user: any;
  children: ReactNode;
}) => {
  const {
    isLoading: isAuth0Loading,
    error: auth0Error,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const [tokenExchanged, setTokenExchanged] = useState(false);
  const [toggleCount, setToggleCount] = useState(0);
  const [error, setError] = useState<Error>();
  const [token, setToken] = useState<string>(accessToken);

  useEffect(() => {
    const tokenExchange = async () => {
      try {
        if (!token || !isTokenStillValid(token)) {
          const auth0Token = await getAccessTokenSilently();

          const updatedToken = await performTokenExchange(auth0Token, tokenExchanged);
          if (updatedToken) {
            setToken(updatedToken);
          }
        }
        setTokenExchanged(true);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
        if (err.error === 'login_required' || err.error === 'consent_required') {
          // avoid flickering of error page and don't set error
          loginWithRedirect();
        } else {
          setError(err);
        }
      }
    };

    if (isAuth0Loading || auth0Error || error) {
      return;
    }

    if (isAuthenticated) {
      tokenExchange();
    } else {
      loginWithRedirect();
    }
  }, [
    isAuth0Loading,
    tokenExchanged,
    auth0Error,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    toggleCount,
    setToken,
    token,
  ]);

  useInterval(() => {
    setToggleCount(toggleCount + 1);
  }, getDelay());

  if (auth0Error || error) {
    return <AuthenticationError />;
  }

  if (isAuthenticated && tokenExchanged && user) {
    setAccessToken(token);
    return <MemoizedAuthProvider user={user}>{children}</MemoizedAuthProvider>;
  }

  return <LoadingPage />;
};