import { parse } from 'cookie';
import { GetServerSideProps } from 'next';

export function getAccessToken({ res, req }: Parameters<GetServerSideProps>[0]) {
  const { cookie } = req.headers;
  let accessToken: string | null = null;
  if (cookie && cookie !== '') {
    const cookies = parse(cookie);
    accessToken = cookies[process.env['server.cookieToken']];
  }
  return accessToken;
}
