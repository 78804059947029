/* generated with graphql-code-generator */

import { gql } from '@apollo/client';
import { GraphQLClient } from 'graphql-request';

import * as Types from './types';
export type CreateUserMutationVariables = Types.Exact<{
  input?: Types.Maybe<Types.CreateUserInput>;
}>;

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  createUser?: Types.Maybe<{ __typename?: 'User' } & Pick<Types.User, 'id'>>;
};

export type ListMembersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type ListMembersQuery = { __typename?: 'Query' } & {
  listMembers?: Types.Maybe<
    { __typename: 'MemberConnection' } & {
      edges: Array<
        { __typename?: 'MemberEdge' } & Pick<Types.MemberEdge, 'cursor'> & {
            node: { __typename?: 'Member' } & Pick<Types.Member, 'userId' | 'position'> & {
                coachingAgreements?: Types.Maybe<
                  Array<Types.Maybe<{ __typename?: 'CoachingAgreement' } & Pick<Types.CoachingAgreement, 'startAt'>>>
                >;
                status?: Types.Maybe<
                  { __typename?: 'CoachingStatus' } & Pick<Types.CoachingStatus, 'reason' | 'status'>
                >;
                organization?: Types.Maybe<{ __typename?: 'Organization' } & Pick<Types.Organization, 'name'>>;
              };
          }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<Types.PageInfo, 'hasNextPage' | 'hasPreviousPage'>;
    }
  >;
};

export type GetLookerDashboardIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type GetLookerDashboardIdQuery = { __typename?: 'Query' } & {
  getOrganization?: Types.Maybe<{ __typename: 'Organization' } & Pick<Types.Organization, 'lookerDashboardId'>>;
};

export type NameDataFragment = { __typename?: 'Name' } & Pick<Types.Name, 'first' | 'last'>;

export type GetOrganizationQueryVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars['ID']>;
  accountId?: Types.Maybe<Types.Scalars['ID']>;
}>;

export type GetOrganizationQuery = { __typename?: 'Query' } & {
  getOrganization?: Types.Maybe<
    { __typename: 'Organization' } & {
      programManager?: Types.Maybe<{ __typename?: 'ProgramManager' } & ProgramManagerDataFragment>;
    } & OrganizationDataFragment
  >;
};

export type ListOrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListOrganizationsQuery = { __typename?: 'Query' } & {
  listOrganizations?: Types.Maybe<
    { __typename: 'OrganizationConnection' } & {
      edges: Array<
        { __typename?: 'OrganizationEdge' } & {
          node: { __typename?: 'Organization' } & {
            programManager?: Types.Maybe<{ __typename?: 'ProgramManager' } & ProgramManagerDataFragment>;
          } & OrganizationDataFragment;
        }
      >;
    }
  >;
};

export type CreateOrganizationMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  logo?: Types.Maybe<Types.Scalars['String']>;
  programManagerId?: Types.Maybe<Types.Scalars['ID']>;
}>;

export type CreateOrganizationMutation = { __typename?: 'Mutation' } & {
  createOrganization?: Types.Maybe<
    { __typename: 'Organization' } & {
      programManager?: Types.Maybe<{ __typename?: 'ProgramManager' } & ProgramManagerDataFragment>;
    } & OrganizationDataFragment
  >;
};

export type UpdateOrganizationMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  name?: Types.Maybe<Types.Scalars['String']>;
  logo?: Types.Maybe<Types.Scalars['String']>;
  programManagerId?: Types.Maybe<Types.Scalars['ID']>;
}>;

export type UpdateOrganizationMutation = { __typename?: 'Mutation' } & {
  updateOrganization?: Types.Maybe<
    { __typename: 'Organization' } & {
      programManager?: Types.Maybe<{ __typename?: 'ProgramManager' } & ProgramManagerDataFragment>;
    } & OrganizationDataFragment
  >;
};

export type OrganizationDataFragment = { __typename?: 'Organization' } & Pick<
  Types.Organization,
  'id' | 'accountId' | 'lookerDashboardId' | 'name' | 'logo'
>;

export type ProgramManagerDataFragment = { __typename: 'ProgramManager' } & Pick<
  Types.ProgramManager,
  'id' | 'picture' | 'phone' | 'mail'
> & { name?: Types.Maybe<{ __typename?: 'Name' } & NameDataFragment> };

export type ListFeedbackMessageQueryVariables = Types.Exact<{
  input: Types.ListFeedbackMessageInput;
}>;

export type ListFeedbackMessageQuery = { __typename?: 'Query' } & {
  listFeedbackMessage?: Types.Maybe<
    { __typename?: 'FeedbackMessageConnection' } & {
      edges: Array<
        { __typename?: 'FeedbackMessageEdge' } & Pick<Types.FeedbackMessageEdge, 'cursor'> & {
            node: { __typename?: 'FeedbackMessage' } & Pick<
              Types.FeedbackMessage,
              'organizationId' | 'userId' | 'id' | 'message' | 'isSelected'
            >;
          }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<Types.PageInfo, 'hasNextPage' | 'hasPreviousPage'>;
    }
  >;
};

export type UpdateFeedbackMessageMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isSelected: Types.Scalars['Boolean'];
}>;

export type UpdateFeedbackMessageMutation = { __typename?: 'Mutation' } & {
  updateFeedbackMessage?: Types.Maybe<
    | ({ __typename?: 'FeedbackMessage' } & Pick<Types.FeedbackMessage, 'id' | 'isSelected'>)
    | { __typename?: 'InvalidArguments' }
    | { __typename?: 'PermissionDenied' }
  >;
};

export type GetOrganizationOfMemberQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;

export type GetOrganizationOfMemberQuery = { __typename?: 'Query' } & {
  member?: Types.Maybe<
    { __typename: 'Member' } & Pick<Types.Member, 'position'> & {
        organization?: Types.Maybe<
          { __typename?: 'Organization' } & Pick<Types.Organization, 'id' | 'accountId' | 'logo' | 'name'> & {
              programManager?: Types.Maybe<{ __typename?: 'ProgramManager' } & ProgramManagerDataFragment>;
            }
        >;
      }
  >;
};

export const OrganizationDataFragmentDoc = gql`
  fragment OrganizationData on Organization {
    id
    accountId
    lookerDashboardId
    name
    logo
  }
`;
export const NameDataFragmentDoc = gql`
  fragment NameData on Name {
    first
    last
  }
`;
export const ProgramManagerDataFragmentDoc = gql`
  fragment ProgramManagerData on ProgramManager {
    __typename
    id
    name {
      ...NameData
    }
    picture
    phone
    mail
  }
  ${NameDataFragmentDoc}
`;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      ... on User {
        id
      }
    }
  }
`;
export const ListMembersDocument = gql`
  query ListMembers($organizationId: ID!) {
    listMembers(input: { organizationId: $organizationId }) {
      __typename
      ... on MemberConnection {
        edges {
          cursor
          node {
            coachingAgreements {
              startAt
            }
            userId
            position
            status {
              reason
              status
            }
            organization {
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
export const GetLookerDashboardIdDocument = gql`
  query GetLookerDashboardId($id: ID!) {
    getOrganization(id: $id) {
      __typename
      ... on Organization {
        lookerDashboardId
      }
    }
  }
`;
export const GetOrganizationDocument = gql`
  query GetOrganization($organizationId: ID, $accountId: ID) {
    getOrganization(id: $organizationId, accountId: $accountId) {
      __typename
      ... on Organization {
        ...OrganizationData
        programManager {
          ...ProgramManagerData
        }
      }
    }
  }
  ${OrganizationDataFragmentDoc}
  ${ProgramManagerDataFragmentDoc}
`;
export const ListOrganizationsDocument = gql`
  query ListOrganizations {
    listOrganizations {
      __typename
      ... on OrganizationConnection {
        edges {
          node {
            ...OrganizationData
            programManager {
              ...ProgramManagerData
            }
          }
        }
      }
    }
  }
  ${OrganizationDataFragmentDoc}
  ${ProgramManagerDataFragmentDoc}
`;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($name: String!, $logo: String, $programManagerId: ID) {
    createOrganization(input: { name: $name, logo: $logo, programManagerId: $programManagerId }) {
      __typename
      ... on Organization {
        ...OrganizationData
        programManager {
          ...ProgramManagerData
        }
      }
    }
  }
  ${OrganizationDataFragmentDoc}
  ${ProgramManagerDataFragmentDoc}
`;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($organizationId: ID!, $name: String, $logo: String, $programManagerId: ID) {
    updateOrganization(id: $organizationId, input: { name: $name, logo: $logo, programManagerId: $programManagerId }) {
      __typename
      ... on Organization {
        ...OrganizationData
        programManager {
          ...ProgramManagerData
        }
      }
    }
  }
  ${OrganizationDataFragmentDoc}
  ${ProgramManagerDataFragmentDoc}
`;
export const ListFeedbackMessageDocument = gql`
  query listFeedbackMessage($input: ListFeedbackMessageInput!) {
    listFeedbackMessage(input: $input) {
      ... on FeedbackMessageConnection {
        edges {
          cursor
          node {
            organizationId
            userId
            id
            message
            isSelected
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
export const UpdateFeedbackMessageDocument = gql`
  mutation UpdateFeedbackMessage($id: ID!, $isSelected: Boolean!) {
    updateFeedbackMessage(input: { id: $id, isSelected: $isSelected }) {
      ... on FeedbackMessage {
        id
        isSelected
      }
    }
  }
`;
export const GetOrganizationOfMemberDocument = gql`
  query GetOrganizationOfMember($userId: ID!) {
    member: getMember(userId: $userId) {
      __typename
      ... on Member {
        organization {
          id
          accountId
          logo
          name
          programManager {
            ...ProgramManagerData
          }
        }
        position
      }
    }
  }
  ${ProgramManagerDataFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateUser(
      variables?: CreateUserMutationVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateUser'
      );
    },
    ListMembers(
      variables: ListMembersQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<ListMembersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListMembersQuery>(ListMembersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ListMembers'
      );
    },
    GetLookerDashboardId(
      variables: GetLookerDashboardIdQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<GetLookerDashboardIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLookerDashboardIdQuery>(GetLookerDashboardIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetLookerDashboardId'
      );
    },
    GetOrganization(
      variables?: GetOrganizationQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<GetOrganizationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOrganizationQuery>(GetOrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrganization'
      );
    },
    ListOrganizations(
      variables?: ListOrganizationsQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<ListOrganizationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListOrganizationsQuery>(ListOrganizationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ListOrganizations'
      );
    },
    CreateOrganization(
      variables: CreateOrganizationMutationVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<CreateOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOrganizationMutation>(CreateOrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateOrganization'
      );
    },
    UpdateOrganization(
      variables: UpdateOrganizationMutationVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<UpdateOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOrganizationMutation>(UpdateOrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateOrganization'
      );
    },
    listFeedbackMessage(
      variables: ListFeedbackMessageQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<ListFeedbackMessageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListFeedbackMessageQuery>(ListFeedbackMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'listFeedbackMessage'
      );
    },
    UpdateFeedbackMessage(
      variables: UpdateFeedbackMessageMutationVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<UpdateFeedbackMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFeedbackMessageMutation>(UpdateFeedbackMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateFeedbackMessage'
      );
    },
    GetOrganizationOfMember(
      variables: GetOrganizationOfMemberQueryVariables,
      requestHeaders?: RequestInit['headers']
    ): Promise<GetOrganizationOfMemberQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOrganizationOfMemberQuery>(GetOrganizationOfMemberDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrganizationOfMember'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
