import { useEffect, useState } from 'react';

import { LinkAccountInput } from '../../apollo/account-api/types/global';
import { useUser } from '../../context/AuthContext';
import { useDashboardBff } from '../useDashboardBff';

export type Response = {
  accounts: LinkAccountInput[];
  error?: Error;
  loading: boolean;
};

export type FetchAccountsInput = { accountId?: string | null; name?: string };

const removeEmpty = (obj: Record<string, string | undefined | null>): Record<string, string> => {
  const filteredEntries = Object.entries(obj).filter(([_, v]) => v != null) as [string, string][];
  return Object.fromEntries(filteredEntries);
};

export default function useFetchAccounts({ accountId, name }: FetchAccountsInput): Response {
  const { accountId: userAccountId } = useUser();
  const { apiClient: dashboardBffApiClient } = useDashboardBff();
  const [accounts, setAccounts] = useState<LinkAccountInput[]>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if accountId is defined as null, we avoid making call
    if (accountId === null) {
      return;
    }

    const urlParams = new URLSearchParams(
      removeEmpty({
        accountId: !accountId && !name ? userAccountId : accountId,
        name,
      })
    );
    const url = `/accounts/search?${urlParams.toString()}`;
    dashboardBffApiClient()
      .get<LinkAccountInput[]>(url)
      .then(({ data }) => {
        setAccounts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [accountId, userAccountId, name]);

  return { accounts, error, loading };
}
